@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .mainApp {
    @apply 
    flex relative
        bg-main-bg
        dark:bg-main-dark-bg
        min-h-screen;
  }

  .content {
    @apply dark:bg-main-dark-bg
        bg-main-bg
        min-h-screen
        w-full
        /* flex
        flex-col
        gap-5 */
  }

  .btn-outline-red {
    @apply px-3 py-2
        text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-center mr-2 mb-2 dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900;
  }

  .btn-outline-yellow {
    @apply px-3 py-2
        text-yellow-400 hover:text-white border border-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm text-center mr-2 mb-2 dark:border-yellow-300 dark:text-yellow-300 dark:hover:text-white dark:hover:bg-yellow-400 dark:focus:ring-yellow-900;
  }

  .btn-dark {
    @apply text-gray-900 hover:text-white border border-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-3 py-2 text-center mr-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800;
  }

  .transition-all-300 {
    @apply transition-all
        ease-out
        duration-300;
  }

  .transition-all-200 {
    @apply transition-all
        ease-out
        duration-200;
  }
  

  /*  Input Floater*/
  .inputFloater {
    @apply block
        rounded-t-lg
        rounded-b-lg
        focus:rounded-b-none
        px-2.5 pb-2.5
        pt-5 w-full
        text-sm
        text-gray-900
        /* dark:bg-gray-700 */
        border-0
        border-b-2
        appearance-none
        transition-all
        duration-200
        ease-in-out
        disabled:cursor-wait
        disabled:bg-gray-200
        disabled:text-gray-600
        disabled:hover:bg-gray-200
        !important;
  }

  .validFalse {
    @apply bg-red-50
        hover:bg-red-100
        border-red-500
        focus:border-red-600
        focus:ring-red-600
        !important;
  }

  .validTrue {
    @apply bg-gray-50
        hover:bg-gray-100
        border-gray-300
        appearance-none
        focus:border-blue-800
        !important;
  }

  .labelLogin {
    @apply absolute
        text-sm
        font-semibold
        text-gray-500
        duration-300
        transform
        -translate-y-4
        scale-75
        top-4 z-10
        origin-[0]
        left-2.5
        peer-focus:text-blue-800
        peer-focus:dark:text-blue-500
        peer-placeholder-shown:scale-100
        peer-placeholder-shown:translate-y-0
        peer-focus:scale-75
        peer-focus:-translate-y-4
        peer-disabled:cursor-wait
        !important;
  }
}

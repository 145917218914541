@tailwind components;

@layer components {
  .loginSection {
    @apply h-screen
        w-full
        bg-gradient-to-r
        from-cyan-900
        to-blue-900
        flex
        justify-center
        items-center;
  }

  .registerBox {
    @apply w-full
        mx-4
        h-5/6
        sm:w-3/4
        sm:h-5/6
        md:w-5/6
        md:h-5/6
        bg-white
        drop-shadow-lg
        rounded-xl
        max-w-4xl
            /* outline
            outline-2
            outline-offset-4
            outline-white */
        overflow-hidden;
  }

  .brandHeader {
    @apply flex
        gap-2
        items-center
        justify-center
        pb-1
        mb-2
        mx-4
        border-b
        border-slate-100;
  }

  .btnForm {
    @apply w-full
        transition-all
        duration-200
        ease-in-out;
  }

  .btnLogin {
    @apply text-white
        bg-blue-800
        hover:bg-blue-900
        focus:ring-4
        focus:ring-blue-300
        font-medium
        rounded-lg
        px-5
        py-2.5
        mr-2 mb-2
        focus:outline-none
        disabled:cursor-not-allowed disabled:bg-slate-600;
  }

  .btnLoginBrands {
    @apply h-10 px-2 flex items-center gap-2 justify-center text-white rounded-lg shadow-md hover:bg-gray-100 cursor-pointer hover:shadow-lg transition-all duration-150 ease-in-out w-full;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .sidebar {
        @apply
        bg-white
        dark:bg-secondary-dark-bg
        h-screen
        fixed
        z-50
        drop-shadow-xl
    }

    .sidebarOpen {
        @apply
        w-60

    }

    .sidebarClosed {
        @apply
        w-0
    }

    .hideTransition {
        @apply
        transition-all
        ease-in-out
        duration-150
    }

    .navLinks {
        @apply
        flex
        items-center
        gap-2
        font-extrabold
        text-lg
        p-3
        mx-3
        rounded-lg
        transition-all
        ease-in-out
        duration-150
        hover:bg-slate-200/50
        dark:hover:bg-main-dark-bg/25
        text-primary
        dark:text-light
    }

    .navLinks.active {
        @apply
        bg-orange-100
        dark:bg-main-dark-bg/75
        dark:text-orange-100
    }

    .logoLink {
        @apply
        items-center
        justify-center
        gap-1
        mx-6
        mt-3
        flex
        text-2xl
        font-extrabold
        tracking-tight
        border-b
        pb-3
        border-gray-300
        dark:border-stone-600
    }

    .themeSwitch {
        @apply
        mx-3
        px-3
        border-t
        border-gray-300
        dark:border-stone-600
        pt-5
    }

    .logoutArea {
        @apply
        flex flex-col
        justify-start
        gap-3
        border-t
        border-gray-300
        dark:border-stone-600
    }

}
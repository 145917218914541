@tailwind components;

@layer components {
  .navbar {
    @apply 
    fixed
        /* md:static */
        block
        bg-main-bg
        dark:bg-main-dark-bg
        z-40
        h-[48px]
  }
}

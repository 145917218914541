@tailwind components;

@layer components {
  .inputRounded-input {
    @apply transition-all ease-out duration-150;
  }

  .inputRounded-input {
    @apply bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 disabled:cursor-pointer read-only:cursor-pointer read-only:bg-gray-300 read-only:dark:bg-gray-900 read-only:border-gray-400;
  }

  .inputRounded-input-validationSuccess {
    @apply bg-green-50 border border-green-500 text-green-900 dark:text-green-400 placeholder-green-700 dark:placeholder-green-500 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-green-500;
  }

  .inputRounded-input-validationError {
    @apply bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 dark:bg-gray-700 focus:border-red-500 block w-full p-2.5 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500;
  }

  .inputRounded-label {
    @apply block mb-1 ml-1 text-xs font-medium text-gray-700 dark:text-gray-300;
  }

  .inputRounded-label-validationSuccess {
    @apply block mb-2 text-xs font-medium text-green-700 dark:text-green-500;
  }

  .inputRounded-label-validationError {
    @apply block mb-2 text-xs font-medium text-red-700 dark:text-red-500;
  }
}

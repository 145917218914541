@tailwind components;

@layer components {
  .inputTaskField {
    @apply
    rounded-xl 
    block
    h-full 
    w-full 
    pl-16 
    p-2 
    
    
    border-0 
    focus:ring-2 
    focus:ring-gray-300 
    focus:border-gray-300 
    dark:placeholder-gray-400 
    dark:text-white 
    dark:focus:ring-gray-500 
    dark:focus:border-gray-500 
    transition-all 
    ease-out 
    duration-200 
    overflow-x-scroll pr-20
  }
  .checkCssIcon {
    @apply
    h-3 
    w-3
    p-2
    rounded-full 
    outline outline-2 outline-offset-2
    outline-gray-400/75 
    dark:outline-gray-200/75 
    dark:hover:outline-gray-200 
    hover:scale-105 
    active:scale-95 
    hover:outline-gray-400 
    transition-all 
    ease-in-out 
    duration-200
    hover:bg-gray-400/75
  }
  .checkCssIcon.checked {
    @apply
    bg-green-500/75
    outline-green-500/75 
    dark:outline-green-400/75 
    dark:hover:outline-green-400  
    hover:outline-green-400 
    hover:bg-green-400
  }
}